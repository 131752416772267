<template>
    <div id="main">
    <div class="section final-cta" id="form">
    <h2>Ty zajmiesz się tym, w czym jesteś <a class="gold">najlepszy</a>. My zajmiemy się znalezieniem dla Ciebie <a class="gold">klientów</a>.</h2>
    <h4>Chcesz wiedzieć, co możemy dla Ciebie zrobić? Wypełnij formularz, a my skontaktujemy się z Tobą.</h4>

    <!-- Form section -->
    <div class="headline-left-form">
        <form >
        <div class="input-container name-container">
            <i class="fas fa-user"></i>
            <input type="text" class="name" placeholder="Imię" v-model="name" />
        </div>
        <div class="input-container email-container">
            <i class="fas fa-envelope"></i>
            <input type="email" class="email" placeholder="E-Mail" v-model="email" />
        </div>
        <div class="input-container company-container">
            <i class="fas fa-building"></i>
            <input type="text" class="company" placeholder="Firma" v-model="company" />
        </div>
        <div class="input-container phone-container">
            <i class="fas fa-phone"></i>
            <input type="text" class="phone" placeholder="Telefon" v-model="phone" />
        </div>
        <div class="input-container question-container">
            <i class="fas fa-question"></i>
            <input type="text" class="question" placeholder="Jakie jest Twoje najważniejsze pytanie?" v-model="question" />
        </div>
        <div class="input-container message-container">
            <i class="fas fa-pencil-alt"></i>
            <textarea placeholder="Wiadomość" v-model="message"></textarea>
        </div>
        <button type="submit" @click.prevent="sendInfo">
            WYŚLIJ
        </button>
        </form>
    </div>

    <a ref="warningRef">{{ warningMessage }}</a>
    </div>
    </div>
</template>
<script>
import { inject, ref, reactive, toRefs } from 'vue'
import axios from 'axios';

export default {
    setup() {
        document.title = "Darmowa Analiza Marketingowa"
        const endpoint = inject("g_endpoint");

        const formData = reactive({
            name: "",
            email: "",
            company: "",
            phone: "",
            question: "",
            message: ""
        });

        const warningMessage = ref("");

        const warningRef = ref(null);

        const validateField = (condition, message) => {
            if (!condition) {
                warningMessage.value = message;
                if (warningRef.value) {
                    warningRef.value.style.visibility = "visible";
                }
                return false;
            }
            return true;
        };


        const sendInfo = async () => {
            if (!validateField(formData.name !== '', 'Podaj imię.')) return;
            if (!validateField(/^\S+@\S+\.\S+$/.test(formData.email), 'Podaj poprawny adres email.')) return;
            if (!validateField(formData.company !== '', 'Podaj nazwę firmy.')) return;
            if (!validateField(/^\d{9}$/.test(formData.phone), 'Numer telefonu powinien składać się z 9 cyfr.')) return;
            if (!validateField(formData.question !== '' || formData.message !== '', 'Pytanie i wiadomość nie mogą być puste.')) return;

            try {
                let dataToSend = {
                    name: formData.name,
                    email: formData.email,
                    company: formData.company,
                    phone_number: formData.phone,
                    main_question: formData.question,
                    message: formData.message
                }
                const jsonFormData = JSON.stringify(dataToSend);

                const response = await axios
                .post(`${endpoint}messages/`, jsonFormData, {
                withCredentials: true,
                headers: {'Content-Type': "application/json"}})

                Object.keys(formData).forEach(key => formData[key] = '');
                warningMessage.value = "Wiadomość wysłana poprawnie";
            } catch (error) {
                console.error("Błąd podczas wysyłania danych:", error);
                warningMessage.value = "Wiadomość nie została wysłana";
            }
        };


        const scrollToForm = () => {
            const formElement = document.getElementById("form");
            if (formElement) {
                formElement.scrollIntoView({ behavior: 'smooth' });
            }
        }

        return { ...toRefs(formData), warningMessage, sendInfo, scrollToForm, warningRef }
    }
}
</script>
<style lang="scss" scoped>
@import '../style/style.scss';
    .gold {
        color: $gold-color;
    }
    #main
    {
        display: flex;
        flex-direction: column;
        gap: 50px;
        max-width: 1200px;
        margin: auto;

        h1 {
            font-size: 4.5rem;
            margin: 0;

            &.bigger {
                font-size: 6rem;
            }
        }

        h2 {
            font-size: 3.4rem;
        }

        h3 {
            font-size: 2.3rem;
        }

        h4 {
            font-size: 1.5rem;
        }

        p {
            font-size: 1.2rem;
        }

        .ultrabig {
            font-size: 6rem;
        }

        .section {
            text-align: center;
        }
        width: 100%;
        display: flex;
        flex-direction: column;

        .final-cta {
        .headline-left-form {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 20px;

            form {
                width: 100%;
                display: flex;
                flex-direction: column;

                .input-container {
                    position: relative;
                    width: 100%;
                    margin-bottom: 20px;

                    input, textarea {
                        width: 100%;
                        padding: 15px 10px 15px 40px;
                        border-radius: 5px;
                        border: 1px solid #ddd;
                        font-size: 1.2rem;
                    }

                    textarea {
                        resize: none;
                        min-height: 120px;
                    }

                    i {
                        position: absolute;
                        left: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                        color: $gold-color;
                        font-size: 1.5rem;
                    }
                }

                button {
                    background-color: $gold-color;
                    color: white;
                    border: none;
                    padding: 10px 20px;
                    font-size: 1.3rem;
                    width: 100%;
                    padding: 30px;
                    cursor: pointer;
                    border-radius: 1000px;
                    transition: 0.3s ease-in-out;

                    &:hover {
                        background-color: white;
                        color: $gold-color;
                    }
                }
            }
        }
    }
}
</style>