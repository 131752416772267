<template>
<div id="main">
	<h1>Blog</h1>
	<h2>Dowiedz się, w jaki sposób stworzyć skuteczny marketing krok po kroku.</h2>
	<div class="articles">
	<router-link
		v-for="article in visibleArticles"
		:key="article.id"
		:to="{ name: 'articlePage', params: { id: article.id, slug: generateSlug(article.title) } }"
		class="article"
	>
		<div class="article-image">
		<img :src="getImageUrl(article.other_source_image_url)" alt="article image" @error="onImageError">
		</div>
		<div class="article-right">
		<div class="article-author">
			<img class="article-author-photo" src="./blogAssets/Rafal.png" alt="author photo">
			<p class="article-author-name">{{ article.author }}</p>
			<p class="article-author-published">{{ formatDate(article.publication_date) }}</p>
			<img class="article-author-share" @click.stop.prevent="shareArticle(article)" src="./blogAssets/share.png" alt="share icon">
		</div>
		<div class="article-title">
			{{ article.title }}
		</div>
		<div class="article-about">
			{{ article.description }}
		</div>
		<div class="article-info">
			<p class="article-info-views">{{ article.views_number }} wyświetleń</p>
			<p class="article-info-likes">{{ article.likes_number }} polubień</p>
		</div>
		</div>
	</router-link>
</div>
<button v-if="moreArticlesAvailable" @click="loadMoreArticles" class="button">
	Załaduj więcej
</button>
</div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';

export default {
	setup() {
	document.title = "Blog RMD Solutions";
	const articles = ref([]);
	const articlesToShow = ref(10);

	const visibleArticles = computed(() => {
		return articles.value.slice(0, articlesToShow.value);
	});

	const moreArticlesAvailable = computed(() => {
		return articlesToShow.value < articles.value.length;
	});

	const loadMoreArticles = () => {
		articlesToShow.value += 10;
	};

	const generateSlug = (title) => {
		const polishToEnglishMap = {
		'ą': 'a', 'ć': 'c', 'ę': 'e', 'ł': 'l', 'ń': 'n', 'ó': 'o', 'ś': 's', 'ź': 'z', 'ż': 'z',
		'Ą': 'a', 'Ć': 'c', 'Ę': 'e', 'Ł': 'l', 'Ń': 'n', 'Ó': 'o', 'Ś': 's', 'Ź': 'z', 'Ż': 'z'
		};

		return title.toLowerCase()
					.split('')
					.map(char => polishToEnglishMap[char] || char)
					.join('')
					.replace(/ /g, '-')
					.replace(/[^\w-]+/g, '');
	};

	const formatDate = (dateString) => {
		const options = { year: 'numeric', month: 'long', day: 'numeric' };
		return new Date(dateString).toLocaleDateString('pl-PL', options);
	};

	const getImageUrl = (url) => {
		return url ? url : 'https://via.placeholder.com/800x400';
	};

	const onImageError = (event) => {
		event.target.src = 'https://via.placeholder.com/800x400';
	};

	const shareArticle = (article) => {
		const articleUrl = `${window.location.origin}/blog/${article.id}/${generateSlug(article.title)}`;
		const title = article.title;

		if (navigator.share) {
		navigator.share({
			title: title,
			url: articleUrl,
		}).then(() => {
			console.log('Article shared successfully');
		}).catch((error) => {
			console.error('Error sharing the article:', error);
		});
		} else if (navigator.clipboard) {
		navigator.clipboard.writeText(articleUrl).then(() => {
			alert('Link do artykułu został skopiowany do schowka');
		}).catch((error) => {
			console.error('Error copying the link:', error);
		});
		} else {
		prompt('Skopiuj ten link do artykułu:', articleUrl);
		}
	};

	const fetchArticles = async () => {
		try {
		const response = await axios.get('https://rmdsolutions.eu:8002/api/blog_posts');
		articles.value = response.data.reverse(); // Odwrócenie kolejności, aby najnowsze były na górze
		} catch (error) {
		console.error('Error fetching articles:', error);
		}
	};

	onMounted(() => {
		fetchArticles();
	});

	return {
		articles,
		visibleArticles,
		moreArticlesAvailable,
		loadMoreArticles,
		generateSlug,
		formatDate,
		getImageUrl,
		onImageError,
		shareArticle
	};
	}
}
</script>

<style lang="scss" scoped>
#main {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 3%;
	margin-bottom: 5%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $main-color;
	z-index: 1000;

	h1 {
		font-size: 3.5em;
		color: $font-color;
		margin-bottom: 1.5rem;
		text-transform: uppercase;
		letter-spacing: 0.05em;
		text-align: center;
	}

	h2 {
		font-size: 2em;
		text-align: center;
		color: $font-color;
		margin-bottom: 2rem;
	}

	.button {
		background-color: $gold-color;
		color: $font-color;
		padding: 1rem 2rem;
		font-size: 1.2em;
		text-transform: uppercase;
		border: none;
		border-radius: 50px;
		cursor: pointer;
		transition: background-color 0.3s ease-in-out;

		&:hover {
			background-color: $font-color;
			color: $gold-color;
		}
	}

	.articles {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-evenly;

		.article {
			width: 90%;
			max-width: 800px;
			aspect-ratio: 10 / 4; // Proporcja artykułu 3:1
			display: flex;
			margin-bottom: 2rem;
			background-color: rgba(255, 255, 255, 0.05);
			border-radius: 12px;
			overflow: hidden;
			transition: transform 0.3s ease-in-out;
			text-decoration: none;

			&:hover {
				transform: scale(1.02);
			}

			.article-image {
				width: 40%; // Obrazek zajmuje 1/3 szerokości
				aspect-ratio: 1 / 1; // Obrazek jest kwadratowy
				overflow: hidden;

				img {
				width: 100%;
				height: 100%;
				object-fit: cover; // Obrazek wypełnia całą swoją część, zachowując proporcje
				}
			}

			.article-right {
				width: 60%; // Tekst zajmuje pozostałe 2/3 szerokości
				padding: 1.5rem;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}

			.article-title {
				font-size: 1.5em;
				margin-bottom: 1rem;
				color: $font-color;
			}

			.article-about {
				font-size: 1em;
				margin-bottom: 1rem;
				color: $font-color;
			}

			.article-author {
				display: flex;
				align-items: center;
				margin-bottom: 1rem;

				.article-author-photo {
				width: 50px;
				height: 50px;
				border-radius: 50%;
				margin-right: 1rem;
				}

				.article-author-name {
				font-size: 1.1em;
				margin-right: 1rem;
				}

				.article-author-published {
				font-size: 1em;
				color: $font-color;
				}

				.article-author-share {
				margin-left: auto;
				cursor: pointer;
				width: 20px;
				height: 20px;
				}
			}

			.article-info {
				display: flex;
				justify-content: space-between;
				border-top: 1px solid $font-color;
				padding-top: 0.5rem;

				.article-info-views,
				.article-info-likes {
				font-size: 1.2em;
				color: $font-color;
				}
			}
		}
	}

	@media (max-width: 768px) {
		margin-top: 0;

		h1 {
			margin-bottom: 0;
		}

		.articles {
			flex-direction: column;

			.article {
				aspect-ratio: initial;
				flex-direction: column;

				.article-image {
					width: 100%; // Obrazek zajmuje 1/3 szerokości
					aspect-ratio: 1 / 1; // Obrazek jest kwadratowy
					overflow: hidden;

					img {
					width: 100%;
					height: 100%;
					object-fit: cover; // Obrazek wypełnia całą swoją część, zachowując proporcje
					}
				}

				.article-right {
					width: 100%; // Tekst zajmuje pozostałe 2/3 szerokości
					padding: 1.5rem;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
				}

				.article-title {
					font-size: 1.5em;
					margin-bottom: 1rem;
					color: $font-color;
				}

				.article-about {
					font-size: 1em;
					margin-bottom: 1rem;
					color: $font-color;
				}

				.article-author {
					display: flex;
					align-items: center;
					margin-bottom: 1rem;

					.article-author-photo {
					width: 50px;
					height: 50px;
					border-radius: 50%;
					margin-right: 1rem;
					}

					.article-author-name {
					font-size: 1.1em;
					margin-right: 1rem;
					}

					.article-author-published {
					font-size: 1em;
					color: $font-color;
					}

					.article-author-share {
					margin-left: auto;
					cursor: pointer;
					width: 20px;
					height: 20px;
					}
				}

				.article-info {
					display: flex;
					justify-content: space-between;
					border-top: 1px solid $font-color;
					padding-top: 0.5rem;

					.article-info-views,
					.article-info-likes {
					font-size: 1.2em;
					color: $font-color;
					}
				}
			}
		}
	}
}
</style>